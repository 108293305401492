<template>
  <div>
    <!-- 头部导航 -->
    <el-header>
      <img class="logo" src="../assets/images/logo_h.png" @click="gohome" />
      <ul class="nav">
        <li
          :class="check == 0 ? 'Check' : ''"
          @mousemove="onlink(0)"
          @mouseout="offlink"
          @click="gohome"
        >
          <p>首页</p>
        </li>
        <li
          v-for="(v, index) in navlist"
          :key="index"
          :class="check == index + 1 ? 'Check' : ''"
          @mousemove="onlink(index + 1)"
          @mouseout="offlink"
        >
          <p>{{ v.name }}</p>
          <div class="c_nav">
            <p
              v-for="(item, i) in v.projectlist"
              :key="i"
              v-show="mousemove == index + 1"
              @mousemove="oncheck(i)"
              :class="c_check == i ? 'c_check' : ''"
              @click="goabout(item.rname)"
            >
              {{ item.pjname }}
            </p>
          </div>
        </li>
      </ul>
      <!-- <div class="search">
        <img src="../assets/images/icon_sousuo.png" />
        <input type="text" placeholder="搜索" />
      </div> -->
      <button @click="gowxd">登录</button>
      <button class="trial" @click="onapply()">免费试用</button>
    </el-header>
    <!-- 内容 -->
    <router-view :childEvent="getapply" />
    <Apply class="Apply" v-show="apply" :childEvent="getapply" />
    <!-- 尾部导航栏 -->
    <div class="footer">
      <div class="footer_l">
        <p class="company">
          <img class="company_logo" src="../assets/images/logo_b.png" />
        </p>
        <img class="rwm" src="../assets/images/rwm.png" />
        <p class="tips">
          <img src="../assets/images/icon_weixin.png" alt="" />
          <span>扫码体验，微心动</span>
        </p>
        <p class="tips">
          <img src="../assets/images/icon_guanzhu.png" alt="" />
          <span>关注微心动，了解更多私域运营知识！</span>
        </p>
      </div>
      <div class="footer_r">
        <div class="footer_nav">
          <div class="nav_c" v-for="(v, index) in navlist" :key="index">
            <h6>{{ v.name }}</h6>
            <p
              v-for="(item, i) in v.projectlist"
              :key="i"
              @click="bottomclick(item.rname, index)"
            >
              {{ item.pjname }}
            </p>
          </div>
        </div>
        <div class="address">
          <div>
            <p>邮箱：hr@sindon.cn</p>
            <p>地址：成都市武侯区天益街38号理想中心1栋1306</p>
          </div>
          <!-- <img src="../assets/images/LOGO.png" /> -->
        </div>
      </div>
    </div>
    <div class="identifier">
      <a href="https://beian.miit.gov.cn/">蜀ICP备12021355号-1</a>
      开发者：成都微心动科技有限公司
    </div>
  </div>
</template>

<script>
import Apply from "../components/Apply.vue";

export default {
  components: {
    Apply,
  },
  data() {
    return {
      // 导航栏
      navlist: [
        {
          name: "CRMC产品",
          projectlist: [
            {
              pjname: "会员权益体系",
              rname: "Equity",
            },
            {
              pjname: "会员分销体系",
              rname: "Distribution",
            },
            {
              pjname: "会员商城体系",
              rname: "Shopping",
            },
            {
              pjname: "会员行为偏好分析&预测体系",
              rname: "Analysis",
            },
            {
              pjname: "会员综合bi平台",
              rname: "Platform",
            },
          ],
        },
        {
          name: "营销应用场景",
          projectlist: [
            {
              pjname: "数字化营销",
              rname: "Digitization",
            },
            {
              pjname: "私域流量运营",
              rname: "Flow",
            },
            {
              pjname: "全渠道SCRM管理平台",
              rname: "SCRM",
            },
            {
              pjname: "营销活动管理",
              rname: "Activity",
            },
            {
              pjname: "会员营销管理",
              rname: "Member",
            },
            {
              pjname: "构建企业级的用户画像体系",
              rname: "Enterprise",
            },
            {
              pjname: "智能化用户标签体系构建",
              rname: "Label",
            },
          ],
        },
        {
          name: "行业案例",
          projectlist: [
            {
              pjname: "酒店行业解决方案",
              rname: "Hotelscheme",
            },
            {
              pjname: "长租式公寓行业解决方案",
              rname: "Apartmentscheme",
            },
            {
              pjname: "大中型连锁餐饮行业解决方案",
              rname: "Cateringprogram",
            },
          ],
        },
        {
          name: "工具与资讯",
          projectlist: [
            {
              pjname: "产品更新",
              rname: "Product",
            },
            {
              pjname: "新闻洞察",
              rname: "News",
            },
            {
              pjname: "心动丨资讯",
              rname: "Consulting",
            },
            {
              pjname: "智能营销主题生成器",
              rname: "Generate",
            },
          ],
        },
        {
          name: "我们",
          projectlist: [
            {
              pjname: "关于我们",
              rname: "Aboutus",
            },
            {
              pjname: "联系我们",
              rname: "Aboutus",
            },
            // {
            //   pjname: "加入我们",
            //   rname: "Joinus",
            // },
            {
              pjname: "合作伙伴",
              rname: "Cooperation",
            },
          ],
        },
      ],
      check: 1,
      mousemove: 0,
      c_check: 0,
      apply: false,
    };
  },
  created() {
    console.log(this.$route.query.id);
    this.check = this.$route.query.id;
  },
  mounted() {
    this.check = this.$route.query.id;
  },
  methods: {
    // 导航栏
    onlink(i) {
      this.check = i;
      this.mousemove = i;
    },
    oncheck(i) {
      this.c_check = i;
    },
    offlink() {
      this.check = this.$route.query.id;
      this.mousemove = 0;
      this.c_check = 0;
    },
    goabout(a) {
      if (a == "Generate") {
        window.location.href = "https://www.convertlab.com/yourtitles/";
        return;
      }
      let index = this.mousemove;
      this.$router.push({
        name: a,
        query: {
          id: index,
        },
      });
    },
    gohome() {
      this.$router.push({ name: "Home" });
    },
    bottomclick(a, i) {
      if (a == "Generate") {
        window.location.href = "https://www.convertlab.com/yourtitles/";
        return;
      }
      this.$router.push({
        name: a,
        query: {
          id: i+1,
        },
      });
    },
    getapply(data) {
      this.apply = data;
    },
    gowxd() {
      window.location.href = "http://hotel.sindon.cn/new/login";
    },
        // 点击试用
    onapply() {
      // this.apply = true;
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
// 头部导航栏
.logo {
  width: 2rem;
  height: 0.38rem;
  margin: 0.31rem 2.1rem 0.31rem 2.4rem;
}
.el-header {
  background-color: #fff;
  color: #333;
  line-height: 1rem;
  height: 1rem !important;
  display: flex;
  .nav {
    display: flex;
    font-size: 0.22rem;
    font-weight: 600;
    li {
      padding: 0 0.16rem;
      margin-bottom: 0.2rem;
      position: relative;
      cursor: pointer;
      .c_nav {
        line-height: 0.2rem;
        width: 2.8rem;
        font-size: 0.2rem;
        background-color: #fff;
        color: #444;
        border-radius: 0.05rem;
        position: absolute;
        top: 1rem;
        left: 0;
        z-index: 999;
        padding-left: 0.16rem;
        p {
          padding: 0.11rem 0;
        }
        p:last-child{
          padding-bottom: 0.2rem;
        }
      }
    }
  }
  button {
    width: 1.16rem;
    height: 0.48rem;
    background-color:#fff ;
    color: #333;
    border: 0.02rem solid #333;
    margin: 0.23rem 0.23rem 0.23rem 0.6rem;
    border-radius: 0.05rem;
  }
      .trial {
    width: 1.16rem;
    height: 0.48rem;
    background-color: #333;
    color: #ffc53d;
    border: none;
    margin: 0.23rem 0.23rem 0.23rem 0rem;
    border-radius: 0.05rem;
  }
  .Check {
    border-bottom: 0.05rem solid #ffc53d;
    color: #ffc53d;
  }
  .c_check {
    color: #ffc53d;
  }
}

// 尾部导航栏
.footer {
  background-color: #666666;
  color: #fff;
  padding: 0 0.64rem 0.3rem;
  display: flex;
  .footer_l {
    padding: 0.58rem 0.9rem;
    .company {
      font-size: 0.18rem;
      letter-spacing: 0.015rem;
      .company_logo {
        padding-top: 0.01rem;
        width: 3.17rem;
        height: 1.39rem;
        display: block;
        margin-bottom: 0.2rem;
      }
    }
    .phone {
      font-size: 0.4rem;
      line-height: 0.76rem;
    }
    .rwm {
      width: 2rem;
      height: 2rem;
    }
    .tips {
      display: flex;
      align-items: center;
      img {
        width: 0.3rem;
        height: 0.3rem;
      }
      span {
        font-size: 0.2rem;
        line-height: 0.56rem;
        padding-left: 0.05rem;
      }
    }
  }
  .footer_r {
    padding-top: 0.64rem;
    flex: 1;
    .footer_nav {
      display: flex;
      justify-content: space-between;
      .nav_c {
        width: 2.1rem;
        h6 {
          font-size: 0.22rem;
          font-weight: 500;
          padding-bottom: 0.14rem;
          border-bottom: 0.02rem solid #fff;
        }
        p {
          color: #f2f2f2;
          font-size: 0.16rem;
          line-height: 0.36rem;
          cursor: pointer;
        }
      }
    }
    .address {
      margin-top: 0.8rem;
      display: flex;
      justify-content: space-between;
      div {
        font-size: 0.2rem;
        line-height: 0.56rem;
      }
      img {
        margin-top: 0.8rem;
        width: 0.84rem;
        height: 0.16rem;
      }
    }
  }
}
.identifier {
  height: 0.64rem;
  font-size: 0.16rem;
  line-height: 0.64rem;
  background-color: #525252;
  color: #fff;
  text-align: center;
  a {
    color: #fff;
    padding-right: 0.28rem;
  }
}
// 试用

.Apply {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
li:hover .c_nav {
  display: block !important;
  overflow: hidden;
  animation: move 1s;
}
@keyframes move {
  0% {
    top: 0.8rem;
  }
  50%{
    top:1.2rem
  }
  100% {
    top:0.8rem ;
  }
}
</style>

